import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import ActivityCard from "../../activity/ActivityCard";
import { breakpoint } from '../../../utils/responsive';

import 'dayjs/locale/zh-hk';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../components/common/buttons/CommonButton';
import HomeTitleGroup from '../../../components/common/typographies/2025HomeTitleGroup';
import ActivityItem from './ActivityItem';

function ActivitySection({ activities }) {
  const { t, i18n } = useTranslation('home');
  const navigate = useNavigate();

  // 3day before startTime

  return (
    <Container>
      <TitleGroupContainer>
        <HomeTitleGroup zhTitle="特別活動" enTitle="Activities" />
      </TitleGroupContainer>
      <div>
        {activities &&
          activities.map((activity, index) => {
            return (
              <ActivityItem
                activity={activity}
                index={index}
                isLast={index === activities.length - 1}
                key={activity._id}
              />
            );
          })}

        <CommonButton type="outlined" onClick={() => navigate('/activity')}>
          {t('home:button.allActivity')}
        </CommonButton>
      </div>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;

  @media ${breakpoint[1024]} {
    min-width: 620px;
  }
  @media ${breakpoint.largeDesktop} {
    min-width: 820px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    min-width: 1150px;
  }
`;

const TitleGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  @media ${breakpoint[1024]} {
    justify-content: start;
    padding: 0;
  }
`;

export default ActivitySection;
