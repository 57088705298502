import React from 'react';
import styled from 'styled-components';
import Circle from '../../../assets/activity_circle.svg';
import CommonImage from '../../../components/common/images/CommonImage';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';

// Can be wrapped in a absolute div

function ActivitySpin({ style, image }) {
  return (
    <StyledAnimationImgDiv style={style}>
      <ResponsiveUI
        mobile={
          <CommonImage
            style={{ objectFit: 'cover' }}
            height="inherit"
            width="inherit"
            src={image || Circle}
          ></CommonImage>
        }
        desktop={
          <CommonImage
            style={{ objectFit: 'cover' }}
            height="inherit"
            width="inherit"
            src={image || Circle}
          ></CommonImage>
        }
      />
    </StyledAnimationImgDiv>
  );
}

const StyledAnimationImgDiv = styled.div`
  height: 100px;
  width: 100px;

  @media ${breakpoint.desktop} {
    height: 200px;
    width: 200px;
  }

  z-index: 1;

  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default ActivitySpin;
