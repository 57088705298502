import { login } from '../../apis';
import CommonButton from '../../components/common/buttons/CommonButton';
import PhoneTextField from '../../components/common/textfields/PhoneTextField';
import EmailTextField from '../../components/common/textfields/EmailTextField';
import PasswordTextField from '../../components/common/textfields/PasswordTextField';
import Space from '../../components/common/Space';
import EmailPhoneToggleButton from '../../components/common/buttons/EmailPhoneToggleButton';
import { useState, useRef, useContext } from 'react';
import TextButton from '../../components/common/buttons/TextButton';
import CommonText from '../../components/common/typographies/CommonText';
import { AppContext } from '../../AppContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import constants from '../../constants';
import ReactGA from 'react-ga4';

export default function Login({ handlePageChange }) {
  const { t, i18n } = useTranslation(['common', 'signin']);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('email');
  const [error, setError] = useState(null);
  const [phoneInput, setPhoneInput] = useState('');
  const { setSession, lateCode, removeLateCode } = useContext(AppContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const validate = () => {
    let error = {};
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    const validPassword = new RegExp(constants.PASSWORD_REGEX);
    if (
      !(
        validEmail.test(emailRef.current.value) &&
        validPassword.test(passwordRef.current.value)
      )
    )
      error.password = t('signin:register.invalid_email_password');
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };
  const handleLogin = async () => {
    if (!loading && validate()) {
      setLoading(true);
      const result = await login(
        emailRef.current.value,
        passwordRef.current.value
      );
      console.log(result);
      if (result.success && result.data?.user?.memberType != 'TMP') {
        localStorage.setItem('session', JSON.stringify(result.data));
        setSession(result.data);
        ReactGA.event('login', {
          method: 'email',
        });
        if (lateCode && removeLateCode) {
          removeLateCode();
        }
      } else {
        setError({ password: t('signin:register.invalid_email_password') });
      }
      setLoading(false);
    }
  };
  return (
    <Container>
      <CommonText fontSize="30px" fontWeight="400" lineHeight="45px">
        {t('signin:signin.title1')}
      </CommonText>
      <CommonText
        type="primary"
        fontSize="60px"
        fontWeight="400"
        lineHeight="65px"
      >
        {t('signin:signin.title2')}
      </CommonText>
      <Space size="80px" />
      {/* <EmailPhoneToggleButton value={type} onChange={(event) => setType(event.target.value)} />
            <Space size="24px" /> */}
      {type == 'email' ? (
        <EmailTextField inputRef={emailRef} label={t('common:email')} />
      ) : (
        <PhoneTextField
          onChange={(value) => setPhoneInput(value)}
          label={t('common:phone')}
        />
      )}
      <Space size="20px" />
      <PasswordTextField
        errorText={error?.password}
        inputRef={passwordRef}
        label={t('common:password')}
      />
      <Space size="9px" />
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <TextButton onClick={() => handlePageChange('forgotpassword')}>
          {t('signin:forgotpassword.title')}
        </TextButton>
      </div>
      <Space size="25px" />
      <ButtonContainer>
        <CommonButton onClick={() => handlePageChange('root')}>
          {t('common:back')}
        </CommonButton>
        <CommonButton type={'primary'} onClick={handleLogin}>
          {t('signin:signin.login')}
        </CommonButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  grid-gap: 30px;
  justify-content: center;
`;
