import React, { useState } from 'react';
import styled from 'styled-components';
import FilterToggleButton from '../../components/common/buttons/FilterToggleButton';
import FilterButton from '../../components/common/buttons/FilterButton';
import TitleWithCounterBar from '../../components/common/bars/TitleWithCounterBar';
import CommonDivider from '../../components/common/divider/CommonDivider';
import SearchButton from '../../components/common/buttons/SearchButton';
import SortImg from '../../assets/Sort.svg';
import Performers from '../../utils/performers.js';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';
import ReactGA from 'react-ga4';

function FilterGroup({
  total,
  open,
  setOpen,
  selected,
  setSelect,
  search,
  setSearch,
  sort,
  setSort,
}) {
  let all = { nameZh: '全部', nameEn: 'all' };
  let mirror = { nameZh: 'mirror', nameEn: 'mirror' };
  const { t, i18n } = useTranslation('discography');
  const [searchOpen, setSearchOpen] = useState(false);

  const onSelectHandler = (performer) => {
    ReactGA.event('discography_filter', {
      param: performer.nameEn,
    });
    setSelect(performer);
    setSearch('');
  };

  const onSortHandler = () => {
    if (sort == 'asc') {
      setSort('desc');
    } else {
      setSort('asc');
    }
  };

  return (
    <StyledMainDiv>
      <ResponsiveUI
        mobile={
          <StyledFilterDiv searchOpen={searchOpen}>
            <div
              style={{
                width: !searchOpen ? '100%' : '0',
                transition: 'width 0.5s',
                visibility: searchOpen && 'hidden',
                marginRight: 10,
              }}
            >
              <FilterToggleButton open={open} setOpen={setOpen}>
                {t('filter')}
              </FilterToggleButton>
            </div>

            {!open && (
              <StyledFilterRightDiv>
                {/* SearchButton */}
                <SearchButton
                  color="white"
                  open={searchOpen}
                  setOpen={setSearchOpen}
                  setSelect={setSelect}
                  search={search}
                  setSearch={(search) => {
                    ReactGA.event('discography_search', {
                      param: search,
                    });
                    setSearch(search);
                  }}
                  onClearSearch={() => {
                    ReactGA.event('discography_search', {
                      param: '',
                    });
                  }}
                ></SearchButton>

                {!searchOpen && (
                  <StyledSortImg
                    sort={sort}
                    src={SortImg}
                    onClick={() => onSortHandler()}
                  ></StyledSortImg>
                )}
              </StyledFilterRightDiv>
            )}
          </StyledFilterDiv>
        }
        desktop={
          <StyledFilterDiv searchOpen={searchOpen}>
            <FilterToggleButton open={open} setOpen={setOpen}>
              {t('filter')}
            </FilterToggleButton>
            <StyledFilterRightDiv>
              {/* SearchButton */}
              <SearchButton
                color="white"
                open={searchOpen}
                setOpen={setSearchOpen}
                setSelect={setSelect}
                search={search}
                setSearch={(search) => {
                  ReactGA.event('discography_search', {
                    param: search,
                  });
                  setSearch(search);
                }}
                onClearSearch={() => {
                  ReactGA.event('discography_search', {
                    param: '',
                  });
                }}
              ></SearchButton>
              {/* SortingButton */}
              <StyledSortImg
                sort={sort}
                src={SortImg}
                onClick={() => onSortHandler()}
              ></StyledSortImg>
            </StyledFilterRightDiv>
          </StyledFilterDiv>
        }
      />

      {open && (
        <div>
          <TitleWithCounterBar
            number={total}
            color="white"
            type="song"
          ></TitleWithCounterBar>
          <CommonDivider></CommonDivider>

          <StyledFilterContentDiv>
            <FilterButton
              onClick={() => onSelectHandler(all)}
              select={selected}
              mWidth="150px"
              dWidth="150px"
              mHeight="50px"
              dHeight="50px"
              fontColor="#EC9023"
            >
              {t('all')}
            </FilterButton>
            <FilterButton
              onClick={() => onSelectHandler(mirror)}
              select={selected}
              mWidth="150px"
              dWidth="150px"
              mHeight="50px"
              dHeight="50px"
              fontColor="#EC9023"
            >
              MIRROR
            </FilterButton>
            {/* map filter buttons */}
            {Performers.map((performer) => {
              return (
                <FilterButton
                  onClick={() => onSelectHandler(performer)}
                  select={selected}
                  mWidth="150px"
                  dWidth="150px"
                  mHeight="50px"
                  dHeight="50px"
                  fontColor="#EC9023"
                >
                  {i18n.language == 'en' ? performer.nameEn : performer.nameZh}
                </FilterButton>
              );
            })}
          </StyledFilterContentDiv>
        </div>
      )}
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  width: 100%;
  overflow: hidden;
`;

const StyledFilterDiv = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const StyledFilterRightDiv = styled.div`
  display: flex;
  align-items: center;

  height: 52px;
  flex: 1;
`;

const StyledSortImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 21.5px;
  cursor: pointer;
  transform: ${(props) => (props.sort == 'desc' ? 'none' : 'scaleY(-1)')};
  transition: all 0.25s;
  @media ${breakpoint.desktop} {
    margin-left: 33px;
    width: 30px;
    height: 30px;
  }
`;

const StyledFilterContentDiv = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 150px);
`;

export default FilterGroup;
