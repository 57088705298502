import { useContext } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { AppContext } from '../AppContext';
import { getLanding, getProfile } from '../apis';
import Loading from '../components/common/loading';
import CommonBottomBar from '../components/common/navbar/CommonBottomBar';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import VisitorWelcome from '../pageComponents/home/VisitorWelcome';
import ActivitySession from '../pageComponents/home/activity/ActivitySection';
import DiscographySession from '../pageComponents/home/member/DiscographySession';
import EshopSession from '../pageComponents/home/member/EshopSession';
import HomeContainer from '../pageComponents/home/member/HomeContainer';
import MemberRecording from '../pageComponents/home/member/MemberRecording';
import MiroCard from '../pageComponents/home/member/MiroCard';
import { breakpoint } from '../utils/responsive';

function Visitor() {
  const { disableMiroReg, session } = useContext(AppContext);

  return (
    <div>
      <VisitorWelcome disableMiroReg={disableMiroReg} user={session?.user} />
    </div>
  );
}
function Member() {
  const { session, setSession } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ['getLanding', session?.accessToken],
    () => getLanding(session?.accessToken)
  );
  const profileQuery = useQuery(['getProfile'], () =>
    getProfile(session.accessToken, { session, setSession })
  );
  console.log(session);
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const isRecordingExists = !!data?.data?.messageToMIRO;

  return (
    <div
      style={{
        background: '#f5f5f5',
      }}
    >
      <CommonNavBar theme="black" />
      <HomeContainer>
        <HomeSection isRecordingExists={isRecordingExists}>
          <MiroCard />
          <ActivitySession activities={data && data.data.activites} />
        </HomeSection>

        {data?.data?.messageToMIRO && (
          <MemberRecording message={data.data.messageToMIRO} />
        )}

        <DiscographySession data={data && data.data.discographies} />

        <EshopSession data={data?.data} />
      </HomeContainer>
      <CommonBottomBar theme="black" />
    </div>
  );
}

const HomeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 50px;
  padding: 0 20px;
  margin-top: 20px;

  @media ${breakpoint.mobile} {
    ${(props) => (props.isRecordingExists ? '' : 'padding-bottom: 40px;')}
  }

  @media ${breakpoint.desktop} {
    margin-top: 0;
  }
  @media ${breakpoint[1024]} {
    flex-direction: row;
    align-items: flex-start;
    gap: 22px;
    padding: 0;
  }
  @media ${breakpoint.largeDesktop} {
    gap: 40px;
  }
  @media ${breakpoint.extraLargeDesktop} {
    gap: 90px;
  }
`;

export default function Home({ scrollProgress }) {
  const { memberType } = useContext(AppContext);
  return memberType === 'MIRO' ? <Member /> : <Visitor />;
}
