import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import CommonText from '../typographies/CommonText';
import { useMediaQuery } from '@mui/material';

// default: black, 100% width as parent
// Sample Usage:
// <TitleWithCounterBar title="2022" type="activity" number="100" color="green" titleProps={{fontSize: '32px', mFontSize: '26px', fontWeight: '700'}} counterProps={{fontSize: '20px', mFontSize: '16px', fontWeight: '400'}}></TitleWithCounterBar>

function TitleWithCounterBar(props) {
  let { title, number, type, color, titleProps, counterProps, style } = props;
  const { t, i18n } = useTranslation(['discography', 'activity']);
  const isDesktop = useMediaQuery(breakpoint.desktop);

  return (
    <StyledDiv style={{ ...style }}>
      {/* <StyledTitleDiv color={color}> {title} </StyledTitleDiv> */}
      <CommonText
        fontWeight="600"
        mFontSize="24px"
        fontSize="30px"
        lineHeight={isDesktop ? '35px' : '32px'}
        type="common"
        uppercase
        color={color}
        {...titleProps}
      >
        {title}
      </CommonText>
      <CommonText
        fontWeight="400"
        mFontSize="14px"
        fontSize="20px"
        lineHeight={isDesktop ? '40px' : '20px'}
        type="common"
        color={color}
        {...counterProps}
      >
        {type == 'activity'
          ? `${number} ${t('activities', { ns: 'activity' })}`
          : `${number} ${t('songs')}`}
      </CommonText>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
`;

export default TitleWithCounterBar;
