import React, { useState } from 'react';
import styled from 'styled-components';
import SearchButton from '../../components/common/buttons/SearchButton';
import SortImg from '../../assets/Sort_black.svg';
import ReactGA from 'react-ga4';

function ActivityFilter({ sort, setSort, search, setSearch }) {
  const [searchOpen, setSearchOpen] = useState();

  const onSortHandler = () => {
    if (sort == 'asc') {
      setSort('desc');
    } else {
      setSort('asc');
    }
  };

  return (
    <StyledMainDiv>
      <StyledFilterDiv>
        {/* SearchButton */}
        <SearchButton
          open={searchOpen}
          setOpen={setSearchOpen}
          isOpenByHover={true}
          color="black"
          search={search}
          setSearch={(search) => {
            ReactGA.event('activity_search', {
              param: search,
            });
            setSearch(search);
          }}
          onClearSearch={() => {
            ReactGA.event('activity_search', {
              param: '',
            });
          }}
        ></SearchButton>
        {/* SortingButton */}

        <StyledSortImg
          sort={sort}
          src={SortImg}
          onClick={() => onSortHandler()}
        ></StyledSortImg>
      </StyledFilterDiv>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  width: 100%;
  overflow: hidden;
`;

const StyledFilterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 45.5px;
`;

const StyledSortImg = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 33px;
  cursor: pointer;
  transform: ${(props) => (props.sort == 'desc' ? 'none' : 'scaleY(-1)')};
  transition: all 0.25s;
`;

export default ActivityFilter;
