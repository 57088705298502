import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

function SearchTextfield(props) {
  return (
    <StyledTextfield
      {...props}
      variant="outlined"
      sx={{
        fontWeight: 700,
        fontFamily: 'Noto Sans TC',
        fontSize: '24px',
      }}
    >
      {props.children}
    </StyledTextfield>
  );
}

const StyledTextfield = styled(TextField)`
  line-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme};
  background: transparent;
  & .MuiOutlinedInput-root {
    width: 100%;
    color: ${(props) => props.theme};

    & fieldset {
      border: none;
      border-radius: 0;
    }
    & input {
      padding: 0 15px;
      font-weight: 700;
      font-size: 24px;
      font-family: 'Open Sans', 'Noto Sans TC';
    }
    &:hover fieldset {
      /* border-color: #FF5C00; */
    }
    &.Mui-focused fieldset {
      /* border-color: #F6C790; */
    }
  }
`;

export default SearchTextfield;
