import { Tooltip } from '@mui/material';
import React from 'react';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CopySVG = ({ color }) => {
  // const colorFromTheme = theme === 'dark' ? '#FFFFFF' : '#000000';
  const circleFillColor = color === 'black' ? '#00000012' : '#FFFFFF33';
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill={circleFillColor} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2083 10.9247V12.2394H26.2089H26.2105V12.2428C26.8424 12.2445 27.4152 12.5099 27.8288 12.9402C28.2391 13.3671 28.4951 13.9573 28.4967 14.6104H28.5V14.6138V27.0759V27.0776H28.4967C28.4951 27.7307 28.2391 28.3243 27.8238 28.7546C27.4119 29.1799 26.8424 29.4452 26.2122 29.4469V29.4503H26.2089H16.0828H16.0812V29.4469C15.4509 29.4452 14.8765 29.1799 14.4629 28.7495C14.0526 28.3226 13.7966 27.7324 13.795 27.0793H13.7917V27.0759V24.2848H11.7911H11.7895V24.2814C11.1592 24.2797 10.5848 24.0144 10.1712 23.5841C9.76095 23.1572 9.50492 22.567 9.50328 21.9138H9.5V21.9104V10.9247V10.923H9.50328C9.50492 10.2681 9.76259 9.67455 10.1762 9.24594C10.5881 8.82073 11.1576 8.5554 11.7878 8.55369V8.55029H11.7911H21.9172H21.9188V8.55369C22.5507 8.5554 23.1235 8.82073 23.5371 9.25104C23.9474 9.67795 24.2034 10.2681 24.205 10.9213H24.2083V10.9247ZM22.4719 12.2394V10.9247V10.9213H22.4752C22.4752 10.7665 22.4112 10.6236 22.3095 10.5182C22.2093 10.4144 22.0698 10.3481 21.9205 10.3481V10.3515H21.9188H11.7927H11.7895V10.3481C11.6401 10.3481 11.5022 10.4144 11.4005 10.5199C11.3004 10.6236 11.2364 10.7682 11.2364 10.923H11.2397V10.9247V21.9104V21.9138H11.2364C11.2364 22.0686 11.3004 22.2115 11.4021 22.3169C11.5022 22.4207 11.6417 22.487 11.7911 22.487V22.4836H11.7927H13.7933V14.6138V14.6121H13.7966C13.7983 13.9573 14.0559 13.3637 14.4695 12.9351C14.8814 12.5099 15.4509 12.2445 16.0812 12.2428V12.2394H16.0844H22.4719ZM26.762 27.0759V14.6138V14.6104H26.7653C26.7653 14.4556 26.7013 14.3128 26.5995 14.2073C26.4994 14.1036 26.3599 14.0372 26.2105 14.0372V14.0406H26.2089H16.0828H16.0795V14.0372C15.9302 14.0372 15.7923 14.1036 15.6906 14.209C15.5904 14.3128 15.5264 14.4573 15.5264 14.6121H15.5297V14.6138V27.0759V27.0793H15.5264C15.5264 27.2341 15.5904 27.377 15.6922 27.4824C15.7923 27.5862 15.9318 27.6525 16.0812 27.6525V27.6491H16.0828H26.2089H26.2122V27.6525C26.3615 27.6525 26.4994 27.5862 26.6011 27.4807C26.7013 27.377 26.7653 27.2324 26.7653 27.0776H26.762V27.0759Z"
        fill={color || '#000000'}
      />
    </svg>
  );
};

const CopyButton = ({ copyText, color }) => {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation('home');

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    setOpen(true);
  };
  return (
    <Tooltip
      title={t('home:miroCard.copied')}
      open={open}
      style={{ cursor: 'pointer' }}
      onClose={() => setOpen(false)}
    >
      <IconButton onClick={handleCopy} disableRipple>
        <CopySVG color={color} />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
