import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import ActivityCard from "../../activity/ActivityCard";
import { breakpoint } from '../../../utils/responsive';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-hk';
import { useNavigate } from 'react-router-dom';
import CommonText from '../../../components/common/typographies/CommonText';
import useActivityItem from '../../activity/useActivityItem';
import Countdown from './Countdown';

const ActivityItem = ({ activity, isLast }) => {
  const { t, i18n } = useTranslation(['home', 'activity']);
  const navigate = useNavigate();

  const {
    isLiveOngoing,
    isAbleToEnroll,
    hasCategoryName,
    remainingTime,
    title,
    coverImage,
    startTime,
    eventCategory,
  } = useActivityItem(activity);

  return (
    <StyledActivityItemContainer isLast={isLast}>
      <StyledActivityItem>
        <StyledCoverImage
          src={coverImage}
          onClick={() => navigate(`/activity/${activity._id}`)}
        />

        <Description onClick={() => navigate(`/activity/${activity._id}`)}>
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            <CommonText
              fontSize="16px"
              style={{ opacity: 0.7 }}
              fontWeight="500"
            >
              {startTime}
            </CommonText>
          </div>
          <CommonText
            mFontSize="16px"
            fontSize="20px"
            fontSizeFor1024="16px"
            largeFontSize="20px"
            className="title"
          >
            {title}
          </CommonText>
          <ActivityItemAttribute>
            {hasCategoryName && (
              <StyledCommonText
                mFontSize="18px"
                fontSize="24px"
                fontSizeFor1024="18px"
                largeFontSize="24px"
                fontWeight="700"
                lineHeight="1.25em"
                style={{ paddingRight: 18 }}
              >
                {eventCategory}
              </StyledCommonText>
            )}

            {isLiveOngoing ? (
              <StyledCommonText
                fontSize="16px"
                style={{
                  opacity: 0.8,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 700,
                }}
              >
                <LiveDot />
                {t('activity:live')}
              </StyledCommonText>
            ) : (
              isAbleToEnroll && (
                <StyledCommonText fontSize="16px">
                  <Countdown remainingTime={remainingTime} />
                </StyledCommonText>
              )
            )}
          </ActivityItemAttribute>
        </Description>
      </StyledActivityItem>
    </StyledActivityItemContainer>
  );
};

const LiveDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: red;
  display: inline-block;
  margin-right: 5px;
`;

const StyledCommonText = styled(CommonText)`
  display: inline-block;
`;

const StyledActivityItemContainer = styled.div`
  border-bottom: ${(props) => !props.isLast && '1px solid #00000033'};
`;

const StyledActivityItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: start;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    gap: 20px;
    padding: 20px;
  }

  &:hover {
    background: #ffffff80;
    border-radius: 20px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }

  @media ${breakpoint.extraLargeDesktop} {
    padding: 46px 20px;
  }
`;

const StyledCoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 21/9;
  border-radius: 5px;
  cursor: pointer;
  @media ${breakpoint.desktop} {
    max-width: 240px;
  }
  @media ${breakpoint[1024]} {
    max-width: 140px;
  }
  @media ${breakpoint.largeDesktop} {
    max-width: 240px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;

  .title {
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

const ActivityItemAttribute = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }

  > div:nth-child(n + 2) {
    @media ${breakpoint.desktop} {
      border-left: 1px solid #00000033;
      padding-left: 26px;
    }
  }
`;

export default ActivityItem;
