import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Styled from 'styled-components';

import 'dayjs/locale/zh';
import Timer from '../../assets/revamp2025/ic-time.svg';
import CommonText from '../../components/common/typographies/CommonText';
import { breakpoint } from '../../utils/responsive';
import { useMediaQuery } from '@mui/material';

const LargeDesktopTime = ({ days, hours, minutes, seconds }) => {
  const { t, i18n } = useTranslation('activity');
  return (
    <StyledContainer>
      {' '}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          flexWrap: 'wrap',
        }}
      >
        <img
          src={Timer}
          alt="timer"
          style={{ marginRight: 5, display: 'inline', height: 20 }}
        />{' '}
        <CommonText
          fontSize="16px"
          largeFontSize="18px"
          fontWeight="700"
          style={{
            display: 'inline',
            opacity: 0.8,
          }}
        >
          {t('enroll_expire_in') + ' '}
        </CommonText>
      </div>
      <CommonText fontWeight="400" fontSize="18px">
        <div style={{ display: 'flex', marginTop: 11 }}>
          <Time>
            <TimeValue fontWeight="400" fontSize="26px">
              {days}
            </TimeValue>
            <TimeSeparator>{t('common:time.day')}</TimeSeparator>
          </Time>
          <CommonText fontWeight="400" fontSize="26px">
            {i18n.language === 'zh' ? '：' : ':'}
          </CommonText>
          <Time>
            <TimeValue fontWeight="400" fontSize="26px">
              {hours}
            </TimeValue>
            <TimeSeparator>{t('common:time.hour')}</TimeSeparator>
          </Time>
          <CommonText fontWeight="400" fontSize="26px">
            {i18n.language === 'zh' ? '：' : ':'}
          </CommonText>
          <Time>
            <TimeValue fontWeight="400" fontSize="26px">
              {minutes}
            </TimeValue>
            <TimeSeparator>{t('common:time.minute')}</TimeSeparator>
          </Time>
          <CommonText fontWeight="400" fontSize="26px">
            {i18n.language === 'zh' ? '：' : ':'}
          </CommonText>
          <Time>
            <TimeValue fontWeight="400" fontSize="26px">
              {seconds}
            </TimeValue>
            <TimeSeparator>{t('common:time.second')}</TimeSeparator>
          </Time>
        </div>
      </CommonText>
    </StyledContainer>
  );
};

const SmallDesktopTime = ({ days, hours, minutes, seconds }) => {
  const { t } = useTranslation('activity');
  return (
    <StyledContainer>
      <img
        src={Timer}
        alt="timer"
        style={{ marginRight: 5, display: 'inline', height: 20 }}
      />{' '}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          flexWrap: 'wrap',
        }}
      >
        <CommonText
          fontSize="16px"
          largeFontSize="18px"
          fontWeight="700"
          style={{
            display: 'inline',
            marginRight: 8,
          }}
        >
          {t('enroll_expire_in') + ' '}
        </CommonText>
        <CommonText fontWeight="400" fontSize="18px">
          <Trans
            t={t}
            i18nKey="activity:enroll_expired_format"
            components={{
              timeSeparator: <TimeSeparator />,
            }}
            values={{
              days: days,
              hours: hours,
              minutes: minutes,
              seconds: seconds,
            }}
          />
        </CommonText>
      </div>
    </StyledContainer>
  );
};

const Countdown = ({ remainingTime }) => {
  const { days, hours, minutes, seconds } = remainingTime || {};
  const isLargeDesktop = useMediaQuery(breakpoint.largeDesktop);

  return isLargeDesktop ? (
    <LargeDesktopTime
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  ) : (
    <SmallDesktopTime
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

const StyledContainer = Styled.div`
  display: flex;
  align-items: center;
  @media ${breakpoint.largeDesktop} {
    flex-direction: column;
    align-items: center;
  }
`;

const Time = Styled.div`
  margin-right: 5px;
  @media ${breakpoint.largeDesktop} {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
`;

const TimeValue = Styled(CommonText)`
  margin-bottom: 4px;
  text-align: center;
`;

const TimeSeparator = Styled.span`
  font-weight: 700;
  opacity: 0.5;
  font-size: 13px;
  text-transform: uppercase;
`;
export default Countdown;
