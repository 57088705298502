import { useMediaQuery } from '@mui/material';
import 'moment/locale/zh-hk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CommonText from '../../components/common/typographies/CommonText';
import { breakpoint } from '../../utils/responsive';
import Countdown from './Countdown';
import useActivityItem from './useActivityItem';

// Props(String): type, date, title, alt(for img description)
// Props(Others): src(image src), onClick
// Sample Usage: <ActivityCard type="secondary" date={'2022年4月3日'} title={'mirror 3週年生日會'} src={'https://i.kfs.io/album/global/126322086,1v1/fit/500x500.jpg'} alt="birthday"></ActivityCard>

function EventStatus({ activity }) {
  const { t, i18n } = useTranslation('activity');
  const { remainingTime, isLiveOngoing, isAbleToEnroll } =
    useActivityItem(activity);

  if (!isAbleToEnroll && !isLiveOngoing) return null;
  return (
    <StyledEventStatusContainer>
      <StyledEventStatus>
        {isLiveOngoing ? (
          <CommonText
            fontSize="16px"
            style={{
              opacity: 0.8,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            <LiveDot />
            {t('activity:live')}
          </CommonText>
        ) : (
          isAbleToEnroll && <Countdown remainingTime={remainingTime} />
        )}
      </StyledEventStatus>
    </StyledEventStatusContainer>
  );
}

function ActivityCard({ activity, ...props }) {
  let { type, onClick } = props;
  let navigate = useNavigate();
  const { t, i18n } = useTranslation('activity');

  const {
    remainingTime,
    isLiveOngoing,
    isAbleToEnroll,
    hasCategoryName,
    coverImage,
    title,
    startTime,
    eventCategory,
  } = useActivityItem(activity);

  const isLargeDesktop = useMediaQuery(breakpoint.largeDesktop);

  const onClickHandler = () => {
    navigate(`./${activity._id}`);
  };

  return (
    <StyledOuterContainer onClick={onClickHandler}>
      <StyledContainer type={type}>
        <StyledImageContainer type={type}>
          <StyledImage src={coverImage} />
        </StyledImageContainer>

        <StyledInfoDiv>
          <StyledDateDiv mFontSize="16px" fontSize="18px" fontWeight="500">
            {startTime}
          </StyledDateDiv>
          <StyledTitleDiv mFontSize="16px" fontSize="20px" textAlign="left">
            {title}
          </StyledTitleDiv>
          <StyledEventContainer>
            {eventCategory && (
              <StyledEventCategory
                mFontSize="18px"
                fontSize="24px"
                fontWeight="700"
              >
                {eventCategory}
              </StyledEventCategory>
            )}
            {!isLargeDesktop && <EventStatus activity={activity} />}
          </StyledEventContainer>
        </StyledInfoDiv>
        {isLargeDesktop && <EventStatus activity={activity} />}
      </StyledContainer>
    </StyledOuterContainer>
  );
}

const StyledOuterContainer = styled.div`
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  margin: 0 -20px;
  padding: 32px 20px;
  @media ${breakpoint.desktop} {
    margin: 0 -40px;
    padding: 30px 40px;
  }
  @media ${breakpoint.largeDesktop} {
    margin: 0 -80px;
    padding: 30px 80px;
  }
`;

const StyledContainer = styled.div`
  @media ${breakpoint.desktop} {
    display: grid;
    grid-template-columns: ${(props) =>
      props.type === 'past' ? '180px 1fr' : '240px 1fr'};
    gap: 30px;
  }
  @media ${breakpoint.largeDesktop} {
    grid-template-columns: ${(props) =>
      props.type === 'past' ? '180px 1fr auto' : '300px 1fr auto'};
  }
`;

const StyledEventContainer = styled.div`
  @media ${breakpoint.desktop} {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  > div:nth-child(n + 2) {
    @media ${breakpoint.desktop} {
      display: flex;
      border-left: 1px solid #00000033;
      padding-left: 18px;
    }
  }
`;

const StyledImageContainer = styled.div`
  aspect-ratio: 21/9;
  margin-bottom: 16px;
  position: relative;

  @media ${breakpoint.desktop} {
    margin-bottom: 0;
  }

  ${(props) =>
    props.type === 'past' &&
    `
  ::before {
    content: '';
    width: 100%;
    aspect-ratio: 21/9;
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 9px; 
  }
    `}
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 21/9;
  object-fit: cover;
  border-radius: 9px;
`;

const StyledTitleDiv = styled(CommonText)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: 1.5;
  text-align: left;
`;

const StyledEventCategory = styled(CommonText)`
  padding-right: 18px;
  margin-top: 6px;
  @media ${breakpoint.desktop} {
    margin: 0;
  }
  text-align: left;
`;

const LiveDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: red;
  display: inline-block;
  margin-right: 5px;
`;

const StyledEventStatusContainer = styled.div`
  margin-top: 16px;
  @media ${breakpoint.desktop} {
    margin: 0;
  }
  @media ${breakpoint.largeDesktop} {
    width: 260px;
  }
`;

const StyledEventStatus = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;

  @media ${breakpoint.largeDesktop} {
    width: 260px;
    height: 100%;
    border-left: 1px rgba(0, 0, 0, 0.11) solid;
    border-radius: 0;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledDateDiv = styled(CommonText)`
  font-size: 16px;
  opacity: 0.7;
  margin-bottom: 12px;
`;

export default ActivityCard;
