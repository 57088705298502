import axios from 'axios';
import constants from '../constants';
import CONSTANTS from '../constants';
import getDeviceType from '../utils/getDeviceType';

const request = (param, refresh = true) => {
  return axios({
    ...param,
  })
    .then((res) => res.data)
    .catch(async (error) => {
      if (
        param.url.indexOf('/token') < 0 &&
        error.response &&
        error.response.status == 401
      ) {
        const sessionJSON = localStorage.getItem('session', '');
        const session = JSON.parse(sessionJSON);
        if (session?.user?.memberType == 'TMP') {
          // do nothing
        } else if (refresh && session?.refreshToken) {
          const refreshResult = await refreshToken(session?.refreshToken);
          console.log(refreshResult);
          if (refreshResult.success) {
            localStorage.setItem('session', JSON.stringify(refreshResult.data));
            // setSession(refreshResult.data)
            console.log('debug refresh success retry request', param);
            // retry original request
            return await request(param, false);
          } else {
            // logout
            console.log('debug refresh fail logout');
            localStorage.removeItem('session');
            window.location = '/';
          }
        } else {
          // logout
          console.log('debug no refresh token logout');
          localStorage.removeItem('session');
          window.location = '/';
        }
      }
      return error?.response?.data || error?.response?.status;
    });
};

const testRefreshToken = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography/admin/discographies/62455c829ba1a2b8c29a31a3/songs`,
  });
};

const register = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/register`,
    headers: { 'Content-Type': 'application/json' },
    data: body,
  });
};

const updateTmpUser = (accessToken, body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile/tmp`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  });
};

const updateUser = (accessToken, body, updateSessions) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  })
    .then((result) => {
      // Check if update session needed
      if (
        updateSessions &&
        updateSessions.session &&
        updateSessions.setSession &&
        result.success
      ) {
        const newSession = {
          ...updateSessions.session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        updateSessions.setSession(newSession);
      }
      return result;
    })
    .catch((error) => {
      return { success: false, error };
    });
};

const subscribe = (accessToken, body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/subscribe`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  });
};

const poll = (choice) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/poll`,

    data: { choice },
  });
};

const forgotPassword = (type, emailOrPhone, phoneOTP) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/forgot-password`,
    headers: { 'Content-Type': 'application/json' },
    data:
      type == 'email'
        ? { email: emailOrPhone }
        : { phone: emailOrPhone, phoneOTP },
  });
};

const resetPassword = (resetPasswordToken, password, retypePassword) => {
  let body = {
    resetPasswordToken: resetPasswordToken,
    password: password,
    retypePassword: retypePassword,
  };
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/reset-password`,
    headers: { 'Content-Type': 'application/json' },
    data: body,
  });
};

const requestEmailOTP = (email, purpose, username) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/email/otp`,
    headers: { 'Content-Type': 'application/json' },
    data: { email, purpose, username },
  });
};

const requestPhoneOTP = (phone) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/phone/otp`,
    headers: { 'Content-Type': 'application/json' },
    data: { phone },
  });
};

const login = (username, password) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');
  params.append('client_id', constants.CLIENT_ID);
  params.append('client_secret', constants.CLIENT_SECRET);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const socialLogin = (type, accessToken) => {
  const params = new URLSearchParams();
  params.append('access_token', accessToken);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const appleLogin = (code, lastName, firstName) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('firstName', firstName);
  params.append('lastName', lastName);
  params.append('clientId', CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append('redirect_url', CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const socialRegister = (
  type,
  accessToken,
  memberType,
  username,
  phone,
  promotion,
  language,
  contactEmail,
  contactEmailOTP
) => {
  const params = new URLSearchParams();
  params.append('access_token', accessToken);
  params.append('memberType', memberType);
  params.append('username', username);
  if (contactEmail) {
    params.append('contactEmail', contactEmail);
    params.append('contactEmailOTP', contactEmailOTP);
  }
  if (phone) params.append('phone', phone);
  if (promotion) params.append('promotion', promotion);
  if (language) params.append('language', language);
  // params.append('recaptcha', recaptcha);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/register`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};
const appleRegister = (
  code,
  lastName,
  firstName,
  memberType,
  username,
  phone,
  promotion,
  language,
  contactEmail,
  contactEmailOTP
) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('firstName', firstName);
  params.append('lastName', lastName);
  params.append('clientId', CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append('redirect_url', CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  params.append('memberType', memberType);
  params.append('username', username);
  if (contactEmail) {
    params.append('contactEmail', contactEmail);
    params.append('contactEmailOTP', contactEmailOTP);
  }
  if (phone) params.append('phone', phone);
  if (promotion) params.append('promotion', promotion);
  if (language) params.append('language', language);
  // params.append('recaptcha', recaptcha);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/register`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const refreshToken = (refreshToken) => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');
  params.append('client_id', constants.CLIENT_ID);
  params.append('client_secret', constants.CLIENT_SECRET);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const getMenu = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/menu`,
  });
};
const getLanding = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/landing`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const getPopup = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/popup`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const listActivities = (accessToken, filter, sort) => {
  let path = '';
  if (filter) {
    path = path + `?filter=${filter}`;
  }
  if (sort) {
    if (path) {
      path = path + `&sort=${sort}`;
    } else {
      path = path + `?sort=${sort}`;
    }
  }
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/activity${path}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const enrollActivity = (accessToken, id, body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/activity/${id}/enroll`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  });
};
const getOrderCounter = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/ordercounter/2025%20MIRO%20Subscription`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
// const enrollActivity = (body) => {
//     const params = new URLSearchParams();
//     params.append('captcha_settings', `{"keyname":"MakerVille_v2","fallback":"true","orgId":"00D8c000006KnjJ","ts":"${new Date().getTime()}"}`);
//     params.append('oid', "00D8c000006KnjJ");
//     params.append('retURL', "http://");
//     params.append('00N8c00000gY6GM', body.miroId);
//     params.append('00N8c00000gY6GR', body.name);
//     params.append('email', body.email);
//     params.append('phone', body.phone);
//     params.append('00N8c00000gY6Ep', body.type);
//     params.append('tac', 'on');
//     params.append('tac2', 'on');
//     params.append('g-recaptcha-response', body.recaptcha);
//     params.append('submit', "Submit");
//     console.log("params", body)
//     return fetch(`https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`, {
//         method: 'post',
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         body: params
//     });
// }

const getActivity = (accessToken, id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/activity/${id}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const listDiscographies = (filter, performerEn, performerZh, sort) => {
  let path = '';
  if (performerEn == 'all') {
    if (filter) {
      path = path + `?filter=${filter}`;
    }
  } else {
    if (path == '' && filter) {
      path = path + `?filter=${filter}`;
    } else if (path && filter) {
      path = path + `&filter=${filter}`;
    }

    if (path == '' && performerEn) {
      path = path + `?performerEn=${performerEn}`;
    } else if (path && performerEn) {
      path = path + `&performerEn=${performerEn}`;
    }

    if (path == '' && performerZh) {
      path = path + `?performerZh=${performerZh}`;
    } else if (path && performerZh) {
      path = path + `&performerZh=${performerZh}`;
    }
  }

  if (sort) {
    if (path) {
      path = path + `&sort=${sort}`;
    } else {
      path = path + `?sort=${sort}`;
    }
  }

  console.log('fetching2', path);
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography${path}`,
  });
};

const getDiscography = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography/${id}`,
  });
};
const getDiscographyRelated = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography/${id}/related`,
  });
};
const listMemberProfiles = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/memberprofile`,
  });
};
const getMemberProfile = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/memberprofile/${id}`,
  });
};
const getTeamProfile = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/teamprofile`,
  });
};
const getProfile = async (accessToken, { session, setSession }) => {
  const result = await request(
    {
      method: 'get',
      url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
      headers: { Authorization: `Bearer ${accessToken}` },
      withCredentials: true,
    },
    true);
    if (result.success && result.data) {
      if(session?.user?.memberType != result?.data?.memberType && result?.data?.memberType == "MIRO"){
        //workaround to gen new token here, this block should be removed after refresh token is fixed
        const refreshResult = await refreshToken(session?.refreshToken);
        if (refreshResult.success) {
          localStorage.setItem('session', JSON.stringify(refreshResult.data));
          setSession({
            ...refreshResult.data,
            user: result.data,
          })
        }
      }
      else{
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        setSession(newSession);
      }
    }
    return result;
};
const updateProfileWithSession = (accessToken, body, updateSessions = {}) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
  })
    .then((result) => {
      // Check if update session needed
      if (
        updateSessions.session &&
        updateSessions.setSession &&
        result.success
      ) {
        const newSession = {
          ...updateSessions.session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        updateSessions.setSession(newSession);
      }
      return result;
    })
    .catch((error) => {
      return { success: false, error };
    });
};
const getOrders = (session) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/orders`,
    headers: { Authorization: `Bearer ${session}` },
  });
};
const getOrderById = (session, id) => {
  return axios
    .get(`${CONSTANTS.API_ENDPOINT}/user/orders/${id}`, {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    })
    .then((res) => res?.data?.data);
};

const getMediaSeriesById = (accessToken, id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/series/${id}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const getMediaTnC = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/tnc`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const validateRenewMiroCode = (code) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/subscribe-code`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      code,
    }),
  });
};

// const contactUs = (body) => {
//     return request({
//         method: 'post',
//         url: `${CONSTANTS.API_ENDPOINT}/case`,
//         headers: { 'Content-Type': 'application/json' },
//         data: body
//     })
// }

const contactUs = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/case`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
  // const params = new URLSearchParams();
  // params.append(
  //   "captcha_settings",
  //   `{"keyname":"MakerVille_v2","fallback":"true","orgId":"00D8c000006KnjJ","ts":"${new Date().getTime()}"}`
  // );
  // params.append("orgid", "00D8c000006KnjJ");
  // params.append("retURL", "http://");
  // params.append("debug", "1");
  // params.append("00N8c00000ddCEh", body.type);
  // params.append("name", body.username);
  // params.append("email", body.email);
  // params.append("subject", "Contact Form Inquiry - " + body.type);
  // params.append("description", body.message);
  // params.append("g-recaptcha-response", body.recaptcha);
  // params.append("submit", "Submit");
  // if (body.phone) params.append("phone", body.phone);
  // if (body.memberNo) params.append("00N8c00000ddCEm", body.memberNo);
  // if (body.file) params.append("00N8c00000ddCHb", body.file);
  // console.log("params", body);
  // return request({
  //   method: "post",
  //   url: `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`,
  //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //   data: params,
  // });
};

const listMedia = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const listMediaSeries = (accessToken) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/series`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const listRegions = (accessToken, type, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/region?lang=${lang}&type=${type}&source=sf`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const listAreas = (accessToken, type, region, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/area?lang=${lang}&type=${type}&${lang}.region=${region}&source=sf`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const listAddresses = (accessToken, type, area, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/address?lang=${lang}&type=${type}&${lang}.area=${area}&source=sf`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const getPayment = (accessToken, id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/payment/${id}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const checkoutStream = (accessToken, contentId, streaming) => {
  const params = new URLSearchParams();
  params.append('contentId', contentId);
  params.append('type', getDeviceType());
  if (streaming) params.append('streaming', streaming);
  return request({
    method: 'post',
    url: CONSTANTS.CHECKOUT_DOMAIN + '/checkout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
    data: params,
  });
};
const keepaliveStream = (accessToken, contentId, session) => {
  const params = new URLSearchParams();
  params.append('contentId', contentId);
  params.append('session', session);
  return request({
    method: 'post',
    url: CONSTANTS.CHECKOUT_DOMAIN + '/keepalive',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
    data: params,
  });
};

const getStaticPage = (pageKey) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/staticpage/page/${pageKey}`,
  });
};

export {
  testRefreshToken,
  register,
  updateTmpUser,
  updateUser,
  forgotPassword,
  resetPassword,
  requestEmailOTP,
  requestPhoneOTP,
  subscribe,
  poll,
  // getPaymentResult,
  updateProfileWithSession,
  getOrders,
  getOrderById,
  getMediaSeriesById,
  getMediaTnC,
  getProfile,
  login,
  socialLogin,
  appleLogin,
  socialRegister,
  appleRegister,
  refreshToken,
  getLanding,
  getMenu,
  getPopup,
  listActivities,
  getActivity,
  enrollActivity,
  // getActivityEnrollment,
  listDiscographies,
  getDiscography,
  getDiscographyRelated,
  getTeamProfile,
  listMemberProfiles,
  getMemberProfile,
  contactUs,
  listMedia,
  listMediaSeries,
  listRegions,
  listAreas,
  listAddresses,
  getPayment,
  getOrderCounter,
  checkoutStream,
  keepaliveStream,
  getStaticPage,
  // renew miro code
  validateRenewMiroCode,
};
