import React from 'react';
import styled from 'styled-components';
import CommonText from './CommonText';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import ExternalLinkIcon from '../../../assets/external_link.svg';

// Options(string): zhTitle, enTitle
// Sample Usage: <TitleGroup zhTitle="特別活動" enTitle="ACTIVITY"></TitleGroup>

function TitleGroup(props) {
  const { i18n } = useTranslation();
  let {
    zhTitle,
    enTitle,
    color,
    external = false,
    flexDirection,
    enTitleStyle,
    zhTitleStyle,
  } = props;

  return (
    <StyledDiv noMarginBottom={true} flexDirection={flexDirection}>
      <StyledDiv flexDirection={flexDirection}>
        {i18n.language === 'zh' && (
          <ZhTitle color={color} style={zhTitleStyle}>
            {zhTitle ? zhTitle : ''}
          </ZhTitle>
        )}

        <ENTitleContainer>
          <ENTitle type="primary" color={color} style={enTitleStyle}>
            {enTitle ? enTitle : ''}
          </ENTitle>
          {external && (
            <img style={{ marginLeft: 10 }} src={ExternalLinkIcon} />
          )}
        </ENTitleContainer>
      </StyledDiv>
    </StyledDiv>
  );
}

const ZhTitle = styled(CommonText)`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;

  @media ${breakpoint.desktop} {
    font-size: 20px;
    line-height: 25px;
  }

  @media ${breakpoint.largeDesktop} {
    font-size: 30px;
    line-height: 45px;
  }
`;

const ENTitle = styled(CommonText)`
  text-transform: uppercase;
  font-size: 33px;
  line-height: 40px;
  font-weight: 400;
  @media ${breakpoint.desktop} {
    font-size: 40px;
    line-height: 50px;
  }
  @media ${breakpoint.largeDesktop} {
    font-size: 60px;
    line-height: 65px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.noMarginBottom ? '0px' : '10px')};
  z-index: 50;
`;

const ENTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default TitleGroup;
