import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// import ActivityCard from "../../activity/ActivityCard";

import 'dayjs/locale/zh-hk';
import Timer from '../../../assets/revamp2025/ic-time.svg';
import CommonText from '../../../components/common/typographies/CommonText';

const Countdown = ({ remainingTime }) => {
  const { t, i18n } = useTranslation('activity');

  const { days, hours, minutes, seconds } = remainingTime || {};

  return (
    <div style={{ display: 'flex' }}>
      <img
        src={Timer}
        alt="timer"
        style={{ marginRight: 5, display: 'inline' }}
      />
      <CommonText fontWeight="400">
        <span style={{ fontWeight: 700, fontSize: 16 }}>
          {t('enroll_expire_in') + ' '}
        </span>
        <Trans
          t={t}
          i18nKey="activity:enroll_expired_format"
          components={{
            timeSeparator: (
              <span
                style={{
                  fontWeight: 700,
                  opacity: 0.5,
                  fontSize: 13,
                }}
              ></span>
            ),
          }}
          values={{
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          }}
        />
      </CommonText>
    </div>
  );
};

export default Countdown;
