import { useTranslation } from 'react-i18next';
import constants from '../../constants';
import PaymentStep from './PaymentStep';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';

export default function Step6({ handlePageChange, setSessionExpired }) {
  const { lateCode } = useContext(AppContext);
  return (
    <PaymentStep
      step=" 6/6"
      price={
        lateCode
          ? constants.LATE_MEMBER_PRICE_PER_YEAR
          : constants.MEMBER_PRICE_PER_YEAR
      }
      handlePageChange={() => handlePageChange('step5')}
    />
  );
}
