import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import SearchImg from '../../../assets/Search.svg';
import SearchBlackImg from '../../../assets/Search_black.svg';
import ArrowImg from '../../../assets/RightArrow.svg';
import ArrowBlackImg from '../../../assets/Arrow_black.svg';
import Cross from '../../../assets/nav_cross.svg';
import WhiteCross from '../../../assets/nav_cross_white.svg';
import SearchTextfield from '../textfields/SearchTextfield';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import { Button, ButtonBase } from '@mui/material';

function SearchButton({
  open,
  setOpen,
  search,
  setSearch,
  onClearSearch,
  setSelect,
  isOpenByHover = true,
  ...props
}) {
  let [input, setInput] = useState('');
  const [isTransitionComplete, setTransitionComplete] = useState(null);

  useEffect(() => {
    setInput(search);
  }, [search]);

  const onSearchHandler = (search) => {
    setSearch(search);
    if (setSelect) {
      setSelect({ nameZh: '全部', nameEn: 'all' });
    }
  };

  const onClickHandler = () => {
    if (!isOpenByHover) {
      setOpen(!open);
    }
  };

  const handleClearSearch = () => {
    setInput('');
    onClearSearch && onClearSearch();
    setSearch('');
    setOpen(false);
    setTransitionComplete(false);
    if (setSelect) {
      setSelect({ nameZh: '全部', nameEn: 'all' });
    }
  };

  const onTouchStartHandler = () => {
    if (isOpenByHover) {
      setOpen(!open);
    }
  };

  const handleMouseLeave = () => {
    if (isOpenByHover && input.length === 0 && open) {
      setOpen(false);
      setTransitionComplete(false);
    }
  };

  const handleMouseEnter = () => {
    if (
      isOpenByHover &&
      !open &&
      (isTransitionComplete || isTransitionComplete === null)
    ) {
      setOpen(true);
      setTransitionComplete(false);
    }
  };

  return (
    <StyledMainDivWrapper
      open={open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledMainDiv
        open={open}
        color={props.color}
        onTransitionEnd={() => {
          setTransitionComplete(true);
        }}
      >
        <StyledSearchImg
          src={props.color == 'white' ? SearchImg : SearchBlackImg}
          isOpenByHover={isOpenByHover}
          onClick={onClickHandler}
          onTouchStart={onTouchStartHandler}
        ></StyledSearchImg>
        {open && (
          <StyledInnerDiv>
            <StyledDiv>
              <SearchTextfield
                onChange={(e) => setInput(e.target.value)}
                value={input}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchHandler(e.target.value);
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                theme={props.color}
              ></SearchTextfield>
            </StyledDiv>
            {search.length === 0 ? (
              <StyledArrowImg
                style={{
                  transform: props.color == 'white' ? 'none' : 'rotate(180deg)',
                }}
                src={props.color == 'white' ? ArrowImg : ArrowBlackImg}
                onClick={(e) => onSearchHandler(input)}
              ></StyledArrowImg>
            ) : (
              <ButtonBase onClick={handleClearSearch}>
                <img
                  src={props.color === 'white' ? WhiteCross : Cross}
                  alt="clear filter"
                  width={13}
                  height={13}
                />
              </ButtonBase>
            )}
          </StyledInnerDiv>
        )}
      </StyledMainDiv>
    </StyledMainDivWrapper>
  );
}

const StyledMainDivWrapper = styled.div`
  // determine the space for animation
  display: flex;
  justify-content: flex-end;
  padding-top: ${(props) => (props.open ? '0px' : '10px')};
  flex: 1;
`;

const StyledMainDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: ${(props) =>
    props.open
      ? props.color == 'white'
        ? '1px solid #F6C790;'
        : '1px solid rgba(17, 17, 17, 0.3);'
      : '1px solid transparent;'}
  width: ${(props) => (props.open ? '100%' : '20px')};
  margin-right: 5px;
  transition: 0.5s ease;
  @media ${breakpoint.desktop} {
    width: ${(props) => (props.open ? '550px' : '25px')};
    margin-left: 10px;
  }
`;

const StyledSearchImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: ${(props) => (props.isOpenByHover ? 'default' : 'pointer')};
  @media ${breakpoint.desktop} {
    height: 25px;
    width: 25px;
  }
`;

const StyledArrowImg = styled.img`
  height: 12px;
  width: 17.6px;
  cursor: pointer;
  color: ${(props) => props.color};
  @media ${breakpoint.desktop} {
    height: 15px;
    width: 22px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  // height: 40px;
  width: 100%;
  @media ${breakpoint.desktop} {
    width: 550px;
  }
`;

const StyledInnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media ${breakpoint.desktop} {
    width: 525px;
  }
`;

export default SearchButton;
