import { useMediaQuery, useTheme } from '@mui/material';
import MuiMenu from '@mui/material/Menu';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../../AppContext';
import NavBlackBurger from '../../../assets/nav_blackBurger.svg';
import NavBlackLogo from '../../../assets/nav_blackLogo.svg';
import CloseIcon from '../../../assets/nav_cross.svg';
import NavWhiteBurger from '../../../assets/nav_whiteBurger.svg';
import NavWhiteLogo from '../../../assets/nav_whiteLogo.svg';
import { ProfileMenuItem } from '../../../pages/ProfileMenu';
import { logout } from '../../../utils/logout';
import {
  breakpoint,
  ResponsiveUI,
  size,
  WindowSize,
} from '../../../utils/responsive';
import Menu from '../../Menu';
import ImageButton from '../buttons/ImageButton';
import LanguageButton from '../buttons/LanguageButton';
import LoginRegisterButton from '../buttons/LoginRegisterButton';
import ProfileButton from '../buttons/ProfileButton';
import CommonText from '../typographies/CommonText';
import JoinBanner from './JoinBanner';
import NavbarLogo from './NavbarLogo';
import NavbarRightContainer from './NavbarRightContainer';
import useJoinBannerControl from './useJoinBannerControl';

const StyledMenu = styled((props) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    // marginTop: theme.spacing(1),
    minWidth: 208,
    color: 'white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
      '&:active': {
        backgroundColor: 'white',
      },
    },
  },
}));

/**
  
   * @param {Object} props - The properties object.
   * @param {string} props.theme - The theme to be applied.
   * @param {function | undefined} props.handleClose - Function to handle the close action.
   * @param {boolean | undefined} props.whiteMenu - Flag to indicate if the white menu is active.
   * @param {Object | undefined} props.style - Custom styles to be applied.
   * @param {React.FC | undefined} props.ToolbarRight - The right toolbar component.
   * @param {React.FC | undefined} props.BannerComponent - The banner component.
   */
function CommonNavBar(props) {
  const { t } = useTranslation(['common', 'profile', 'menu']);
  const { memberType, session, setSession, disableMiroReg } =
    useContext(AppContext);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let { theme, handleClose, whiteMenu, style, ToolbarRight, BannerComponent } =
    props;
  const login =
    session?.user?.memberType == 'MIRO' || session?.user?.memberType == 'WEB';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const username = session?.user?.username;
  const is1024 = useMediaQuery(breakpoint[1024]);
  const muiTheme = useTheme();
  const { openBanner, setOpenBanner, handleCloseBanner } = useJoinBannerControl(
    {
      onOpenBanner: props?.onOpenBanner,
    }
  );

  useEffect(() => {
    if (is1024) {
      setOpen(false);
    }
  }, [is1024]);

  function handleLogout() {
    setAnchorEl(null);
    logout({ navigate, setSession });
  }

  return (
    <header
      style={{
        position: 'sticky',
        top: 0,
        background: 'inherit',
        zIndex: muiTheme.zIndex.appBar,
        ...style,
      }}
    >
      {BannerComponent !== null && openBanner && (
        <JoinBanner type={openBanner} handleClose={handleCloseBanner} />
      )}

      <StyledMainDiv openBanner={openBanner} className="navbar">
        {handleClose ? (
          <StyledLeftDiv>
            <ResponsiveUI
              mobile={
                <>
                  <ImageButton onClick={handleClose} src={CloseIcon} />
                  <ResponsiveUI
                    mobile={<div></div>}
                    desktop={<LanguageButton />}
                  />
                </>
              }
              desktop={<NavbarLogo theme={theme} />}
              mobileOffset={size[1024] - size.mobile - 1}
            />
          </StyledLeftDiv>
        ) : (
          <StyledLeftDiv>
            <ResponsiveUI
              mobile={
                <ImageButton
                  onClick={() => setOpen(true)}
                  src={theme === 'black' ? NavBlackBurger : NavWhiteBurger}
                />
              }
              desktop={<NavbarLogo theme={theme} />}
              mobileOffset={size[1024] - size.mobile - 1}
            />
            <Menu open={open} handleClose={() => setOpen(false)} />
          </StyledLeftDiv>
        )}
        {/* onClick={ toHome() } */}
        {!is1024 && (
          <StyledLogo
            src={theme === 'black' ? NavBlackLogo : NavWhiteLogo}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          ></StyledLogo>
        )}

        <StyledRightDiv>
          {is1024 && <NavbarRightContainer theme={theme} />}

          {!login ? (
            <Link to={'/signin'} style={{ textDecoration: 'none' }}>
              <LoginRegisterButton whiteMenu={whiteMenu}></LoginRegisterButton>
            </Link>
          ) : (
            <ProfileButton
              handleClose={handleClose}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              name={username}
              blackTheme={theme === 'black'}
            ></ProfileButton>
          )}
        </StyledRightDiv>

        {/**logined floating menu */}
        <StyledMenu
          elevation={0}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          keepMounted
          style={{ zIndex: 9999 }}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <StyledProfileMenuContainer>
            <Link
              to={`/${ProfileMenuItem.PROFILE_INFO}`}
              style={{ textDecoration: 'none' }}
            >
              <CommonText fontWeight="600" size="18px" lineHeight="24px">
                {t('profile:menu.mange_personal_info')}
              </CommonText>
            </Link>

            <StyledProfileMenuRow>
              <Link
                to={`/${ProfileMenuItem.PROFILE_ORDER}`}
                style={{ textDecoration: 'none' }}
              >
                <CommonText fontWeight="600" size="18px" lineHeight="24px">
                  {t('profile:menu.my_order')}
                </CommonText>
              </Link>
            </StyledProfileMenuRow>

            {session?.user?.memberExpiresAt && (
              <StyledProfileMenuRow>
                <Link
                  to={`/${ProfileMenuItem.PROFILE_MEMBERSHIP}`}
                  style={{ textDecoration: 'none' }}
                >
                  <CommonText fontWeight="600" size="18px" lineHeight="24px">
                    {t('profile:menu.my_membership')}
                  </CommonText>
                </Link>
              </StyledProfileMenuRow>
            )}

            <StyledProfileMenuRow>
              <Link to={`/`} style={{ textDecoration: 'none' }}>
                <CommonText
                  onClick={handleLogout}
                  fontWeight="600"
                  size="18px"
                  lineHeight="24px"
                >
                  {t('profile:menu.logout')}
                </CommonText>
              </Link>
            </StyledProfileMenuRow>
          </StyledProfileMenuContainer>
        </StyledMenu>
      </StyledMainDiv>
    </header>
  );
}

const StyledProfileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px;
`;
const StyledProfileMenuRow = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;

const StyledMainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1920px;
  margin: auto;

  @media ${breakpoint.mobile} {
    height: ${(props) => props.height ?? '75px'};
    padding-left: 20px;
    padding-right: 20px;
  }

  @media ${breakpoint.desktop} {
    height: ${(props) => props.desktopHeight ?? '80px'};
  }
`;

const StyledLogo = styled.img`
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 76px;

  @media ${breakpoint.mobile} {
    width: 50px;
    height: 51px;
  }

  @media ${breakpoint.desktop} {
    height: 60px;
  }

  @media ${breakpoint.largeDesktop} {
    height: 80px;
  }
`;

const StyledRightDiv = styled.div`
  display: flex;
  align-items: center;
  right: 16px;
`;

const StyledLeftDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledShopDiv = styled.div`
  cursor: pointer;
  margin-right: 50px;
`;

export default CommonNavBar;
