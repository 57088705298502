import React from 'react';
import styled from 'styled-components';
import ActivityFilter from './ActivityFilter';
import TitleWithCounterBar from '../../components/common/bars/TitleWithCounterBar';
import CommonDivider from '../../components/common/divider/CommonDivider';
import ActivityCard from './ActivityCard';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';
import { useMediaQuery } from '@mui/material';

export default function ActivitySection({
  sort,
  setSort,
  search,
  setSearch,
  data,
  isFetching,
}) {
  const { t, i18n } = useTranslation('activity');
  const isDesktop = useMediaQuery(breakpoint.desktop);

  return (
    <StyledDiv>
      <StyledFilterDiv>
        <ActivityFilter
          sort={sort}
          setSort={setSort}
          search={search}
          setSearch={setSearch}
        ></ActivityFilter>
      </StyledFilterDiv>

      {data.ongoing.length >= 1 ? (
        <div>
          {data.ongoing.length >= 1 && (
            <div style={{ width: '100%' }}>
              <TitleWithCounterBar
                title={t('latest')}
                type="activity"
                number={Object.keys(data.ongoing).length}
                color="black"
                titleProps={{
                  fontSize: '32px',
                  mFontSize: '26px',
                  fontWeight: '700',
                }}
                counterProps={{
                  fontSize: '20px',
                  mFontSize: '16px',
                  fontWeight: '400',
                }}
                style={{ marginBottom: isDesktop ? '20px' : '17px' }}
              ></TitleWithCounterBar>
              <StyledDividerDiv className="ongoing">
                <CommonDivider
                  style={{ borderColor: 'black', opacity: 0.2 }}
                ></CommonDivider>
              </StyledDividerDiv>
              <StyledOnGoingDiv>
                {data.ongoing.map((activity, index) => {
                  return (
                    <>
                      <ActivityCard
                        key={index}
                        activity={activity}
                        type="ongoing"
                      ></ActivityCard>
                      <CommonDivider
                        style={{ borderColor: 'black', opacity: 0.2 }}
                      />
                    </>
                  );
                })}
              </StyledOnGoingDiv>
            </div>
          )}
        </div>
      ) : (
        !search &&
        !isFetching && (
          <StyledNoActivityDiv>
            <div>
              <CommonText
                mFontSize="16px"
                fontSize="20px"
                largeFontSize="30px"
                type="common"
              >
                敬請期待未來最新活動！
              </CommonText>
              <CommonText mFontSize="33px" fontSize="40px" largeFontSize="60px">
                Stay tuned for our upcoming activities!
              </CommonText>
            </div>
          </StyledNoActivityDiv>
        )
      )}

      {data.past.length >= 1 && (
        <div>
          <TitleWithCounterBar
            title={t('past')}
            type="activity"
            number={Object.keys(data.past).length}
            color="black"
            titleProps={{
              fontSize: '32px',
              mFontSize: '26px',
              fontWeight: '700',
            }}
            counterProps={{
              fontSize: '20px',
              mFontSize: '16px',
              fontWeight: '400',
            }}
            style={{ marginBottom: isDesktop ? '20px' : '17px' }}
          ></TitleWithCounterBar>
          <StyledDividerDiv className="past">
            <CommonDivider
              style={{ borderColor: 'black', opacity: 0.2 }}
            ></CommonDivider>
          </StyledDividerDiv>
          <StyledPastDiv>
            {data.past.map((activity, index) => {
              return (
                <>
                  <ActivityCard
                    key={index}
                    activity={activity}
                    type="past"
                  ></ActivityCard>
                  <CommonDivider
                    style={{ borderColor: 'black', opacity: 0.2 }}
                  />
                </>
              );
            })}
          </StyledPastDiv>
        </div>
      )}

      {data.ongoing.length == 0 && data.past.length == 0 && search && (
        <StyledNotFoundDiv>
          <CommonText fontSize="30px" type="common">
            {t('noResult')}
          </CommonText>
        </StyledNotFoundDiv>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFilterDiv = styled.div`
  margin-bottom: 40px;
`;

const StyledOnGoingDiv = styled.div`
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;

  @media ${breakpoint.desktop} {
    margin-bottom: 128px;
  }
  @media ${breakpoint.largeDesktop} {
    margin-bottom: 110px;
  }
`;

const StyledPastDiv = styled.div`
  /* margin-bottom: 52px; */
  display: flex;
  flex-direction: column;
`;

const StyledDividerDiv = styled.div`
  width: 100%;
`;

const StyledNotFoundDiv = styled.div`
  margin-top: 60px;
  padding-bottom: 370px;
`;

const StyledNoActivityDiv = styled.div`
  margin-top: 60px;
  margin-bottom: 80px;
`;
