import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { getOrders } from '../../../apis';
import { AppContext } from '../../../AppContext';
import CommonDivider from '../../../components/common/divider/CommonDivider';
import Space from '../../../components/common/Space';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';
import OrderItem from './OrderItem';
import OrderEmptyIcon from '../../../assets/order_empty.png';
import { Box, Stack } from '@mui/material';
import CommonText from '../../../components/common/typographies/CommonText';

function OrderList(props) {
  const { t } = useTranslation('profile');
  const { session } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery('getOrders', () =>
    getOrders(session.accessToken)
  );

  const _renderContent = (isMobile) => {
    return (
      <StyledMainDiv>
        {isMobile && <Space size="33px" />}
        {data?.data?.length >= 1 ? (
          <CommonDivider />
        ) : (
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <OrderEmptyImage src={OrderEmptyIcon} />
            <CommonText color="rgb(0,0,0,0.5)">
              {t('order.no_record')}
            </CommonText>
          </Stack>
        )}
        {data?.data?.map((order) => (
          <OrderItemListDiv>
            <OrderItem order={order} />
            <CommonDivider />
          </OrderItemListDiv>
        ))}
      </StyledMainDiv>
    );
  };
  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const OrderItemListDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const OrderEmptyImage = styled.img`
  display: flex;
  width: 300px;
  @media ${breakpoint.mobile} {
    width: 200px;
  }
`;

export default OrderList;
