import styled from 'styled-components';
import BlackFbIcon from '../../../assets/footer_fbBlack.svg';
import WhiteFbIcon from '../../../assets/footer_fbWhite.svg';
import BlackIgIcon from '../../../assets/footer_igBlack.svg';
import WhiteIgIcon from '../../../assets/footer_igWhite.svg';
import BlackXIcon from '../../../assets/footer_xBlack.svg';
import WhiteXIcon from '../../../assets/footer_xWhite.svg';
import BlackYoutubeIcon from '../../../assets/footer_youtubeBlack.svg';
import WhiteYoutubeIcon from '../../../assets/footer_youtubeWhite.svg';
import colors from '../../../utils/colors.js';
import { WindowSize, breakpoint } from '../../../utils/responsive';
import License from '../footers/License';
import CommonText from '../typographies/CommonText';

import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import NavBlackLogo from '../../../assets/nav_blackLogo.svg';
import NavWhiteLogo from '../../../assets/nav_whiteLogo.svg';
import VerticalDivider from '../../common/divider/VerticalDivider';
import MenuItem from './MenuItem.js';
import NavText from './NavText.js';
import useMenus from './useMenus.js';

const links = {
  fanclub_instagram: 'https://www.instagram.com/miro.weallare/',
  fanclub_facebook: 'https://www.facebook.com/MIRO.weallare',
  official_instagram: 'https://www.instagram.com/mirror.weare/',
  official_facebook: 'https://www.facebook.com/MIRROR.WeAre/',
  official_youtube: 'https://www.youtube.com/c/MIRRORweare',
  official_x: 'https://x.com/MIRROR_weare',
};

const colorList = {
  yellow: '#AB8672;',
  grey: '#C4C6C2',
  greyBlue: '#B7C2CD',
  purple: '#C3A8FF',
  orange: '#EC9022',
  black: '#000000',
  darkPurple: '#4F3FB2',
  white: '#F5F5F5',
};

const SocialMedia = ({ theme }) => {
  const handleClick = (link, linkType) => {
    ReactGA.event('outbound_click_from_home', {
      param: link,
    });
    window.open(link, '_blank');
  };
  const isMobile = WindowSize() === 'mobile';
  return (
    <StyledSocialMedia>
      <SocialMediaSection>
        <CommonText
          fontSize="12px"
          fontWeight="700"
          className="text"
          color={theme === 'black' ? colors.black : colors.white}
        >
          MIRROR OFFICIAL FAN CLUB
        </CommonText>
        {isMobile && <VerticalDivider />}
        <SocialMediasContainer>
          <IconButton
            src={theme === 'black' ? BlackIgIcon : WhiteIgIcon}
            onClick={() => handleClick(links['fanclub_instagram'], 'instagram')}
          />
          <IconButton
            src={theme === 'black' ? BlackFbIcon : WhiteFbIcon}
            onClick={() => handleClick(links['fanclub_facebook'], 'facebook')}
          />
        </SocialMediasContainer>
      </SocialMediaSection>{' '}
      <SocialMediaSection>
        <CommonText
          fontSize="12px"
          fontWeight="700"
          className="text"
          color={theme === 'black' ? colors.black : colors.white}
        >
          MIRROR
        </CommonText>
        {isMobile && <VerticalDivider />}
        <SocialMediasContainer>
          <IconButton
            src={theme === 'black' ? BlackIgIcon : WhiteIgIcon}
            onClick={() =>
              handleClick(links['official_instagram'], 'instagram')
            }
          />
          <IconButton
            src={theme === 'black' ? BlackFbIcon : WhiteFbIcon}
            onClick={() => handleClick(links['official_facebook'], 'facebook')}
          />
          <IconButton
            src={theme === 'black' ? BlackYoutubeIcon : WhiteYoutubeIcon}
            onClick={() => handleClick(links['official_youtube'], 'youtube')}
          />
          <IconButton
            src={theme === 'black' ? BlackXIcon : WhiteXIcon}
            onClick={() => handleClick(links['official_x'], 'x')}
          />
        </SocialMediasContainer>
      </SocialMediaSection>
    </StyledSocialMedia>
  );
};

const CommonBottomBar = ({ theme, bgColor }) => {
  const isMobile = WindowSize() === 'mobile';
  const { t, i18n } = useTranslation('menu');
  const navigate = useNavigate();

  const { footerMenusFromApi } = useMenus();

  return (
    <Container bgColor={bgColor ? colorList[bgColor] : null}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isMobile && (
          <LeftContainer>
            <img
              src={theme === 'black' ? NavBlackLogo : NavWhiteLogo}
              alt="logo"
              style={{ width: 90, cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            />
            <NavText theme={theme} />
          </LeftContainer>
        )}
        <RightContainer isMobile={isMobile} theme={theme}>
          {!isMobile && (
            <Sections>
              {footerMenusFromApi.map((menu) => {
                return <MenuItem menuItem={menu} theme={theme} />;
              })}
            </Sections>
          )}
          <SocialMedia theme={theme} />
        </RightContainer>
      </div>
      <BottomContainer>
        {!isMobile && (
          <div style={{ display: 'flex' }}>
            <StyledLink to="/contact-us">
              <CommonText
                fontSize="12px"
                fontWeight="500"
                color={theme === 'black' ? colors.black : colors.white}
              >
                {t('contactUs')}
              </CommonText>
            </StyledLink>
            <StyledLink to="/external-link">
              <CommonText
                fontSize="12px"
                fontWeight="500"
                color={theme === 'black' ? colors.black : colors.white}
              >
                {t('externalLink')}
              </CommonText>
            </StyledLink>
            <StyledLink to="/faq">
              <CommonText
                fontSize="12px"
                fontWeight="500"
                color={theme === 'black' ? colors.black : colors.white}
              >
                {t('FAQ')}
              </CommonText>
            </StyledLink>
            <StyledLink to="/tnc">
              <CommonText
                fontSize="12px"
                fontWeight="500"
                color={theme === 'black' ? colors.black : colors.white}
              >
                {t('TNC')}
              </CommonText>
            </StyledLink>
            <StyledLink to="/privacy-terms">
              <CommonText
                fontSize="12px"
                fontWeight="500"
                color={theme === 'black' ? colors.black : colors.white}
              >
                {t('privacypolicy')}
              </CommonText>
            </StyledLink>
          </div>
        )}
        <License
          disableDivider
          color={theme === 'black' ? colorList.black : colors.white}
        ></License>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${(props) => props.bgColor && props.bgColor};
  height: auto;
  width: 100%;
  max-width: 1920px;
  margin: auto;

  @media ${breakpoint.mobile} {
    padding: 15px 20px;
  }

  @media ${breakpoint.desktop} {
    gap: 60px;
    padding: 70px 40px;
    padding-bottom: 40px;
  }

  @media ${breakpoint[1024]} {
    padding: 70px 85px;
    padding-bottom: 40px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  > div {
    ${(props) => {
      return !props.isMobile
        ? props.theme === 'black'
          ? `border-left: 1px solid rgba(0, 0, 0, 0.2);`
          : `border-left: 1px solid rgba(255, 255, 255, 0.2);`
        : null;
    }}
    padding-left: 30px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Sections = styled.div`
  height: 240px;
  width: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
`;

const StyledSocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${breakpoint.desktop} {
    gap: 33px;
  }
`;

const SocialMediaSection = styled.div`
  display: flex;
  justify-content: end;
  @media ${breakpoint.desktop} {
    text-align: start;
    flex-direction: column;
    .text {
      margin-bottom: 12px;
    }
  }
`;

const SocialMediasContainer = styled.div`
  display: flex;
  text-align: end;
  gap: 20px;
  @media ${breakpoint.mobile} {
    gap: 10px;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  margin-right: 20px;
`;
const IconButton = styled.img`
  cursor: pointer;
  width: 20px;
  @media ${breakpoint.mobile} {
    width: 12px;
  }
`;

export default CommonBottomBar;
