import dayjs from 'dayjs';
import 'dayjs/locale/zh-hk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useEnrollActivityCountdown = (endTime, startTime) => {
  const [diff, setDiff] = useState({
    days: dayjs(endTime).diff(dayjs(), 'day'),
    hours: dayjs(endTime).diff(dayjs(), 'hour') % 24,
    minutes: dayjs(endTime).diff(dayjs(), 'minute') % 60,
    seconds: dayjs(endTime).diff(dayjs(), 'second') % 60,
  });

  useEffect(() => {
    if (startTime && endTime) {
      const interval = setInterval(() => {
        setDiff({
          days: dayjs(endTime).diff(dayjs(), 'day'),
          hours: dayjs(endTime).diff(dayjs(), 'hour') % 24,
          minutes: dayjs(endTime).diff(dayjs(), 'minute') % 60,
          seconds: dayjs(endTime).diff(dayjs(), 'second') % 60,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [endTime, startTime]);

  const days = diff.days.toString();
  const hours = diff.hours.toString();
  const minutes = diff.minutes.toString().padStart(2, '0');
  const seconds = diff.seconds.toString().padStart(2, '0');

  return endTime
    ? {
        days,
        hours,
        minutes,
        seconds,
      }
    : null;
};

const useActivityItem = (activity) => {
  const remainingTime = useEnrollActivityCountdown(
    activity.endTime,
    activity.startTime
  );
  const { t, i18n } = useTranslation('activity');

  const isLiveOngoing =
    activity.isLive &&
    dayjs().isAfter(dayjs(activity.liveStartTime)) &&
    dayjs().isBefore(dayjs(activity.liveEndTime));

  /**
   * @type {boolean}
   */
  const isAbleToEnroll =
    !activity.isLive &&
    activity.applicationForm &&
    dayjs().isAfter(dayjs(activity.startTime)) &&
    dayjs().isBefore(dayjs(activity.endTime));

  /**
   * @type {boolean}
   */
  const hasCategoryName =
    (activity.eventCategoryZh && activity.eventCategoryZh !== ' ') ||
    (activity.eventCategoryEn && activity.eventCategoryEn !== ' ');

  const isPast = dayjs().isAfter(dayjs(activity.endTime));

  return {
    remainingTime,
    isLiveOngoing,
    isAbleToEnroll,
    hasCategoryName,
    isPast,
    coverImage: activity.coverImage + '-thumbnail',
    title:
      i18n.language === 'zh'
        ? activity.titleZh
        : activity.titleEn || activity.titleZh,
    startTime: dayjs(activity.startTime).format(
      i18n.language === 'zh' ? 'YYYY年M月D日' : 'D MMMM YYYY'
    ),
    eventCategory: hasCategoryName
      ? i18n.language === 'en'
        ? activity.eventCategoryEn
        : activity.eventCategoryZh
      : null,
  };
};

export default useActivityItem;
