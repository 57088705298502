import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import constants from '../constants';
import Loading from '../components/common/loading';
import { useQuery } from 'react-query';
import { validateRenewMiroCode } from '../apis';
import moment from 'moment';

export default function Join2025({ memberType }) {
  const { t, i18n } = useTranslation();
  const {
    session,
    setSession,
    disableMiroReg,
    lateCode,
    setLateCode,
    removeLateCode,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get('code') || lateCode;
  console.log('code', code);
  const lang = i18n.language == 'zh' ? 'tc' : 'en';

  const { isLoading, isError, data } = useQuery(['renewMiroCode', code], () =>
    validateRenewMiroCode(code)
  );

  const isMiro = session?.user?.memberType === 'MIRO';
  const isLatestMiro =
    session?.user?.memberType === 'MIRO' &&
    !moment(session?.user?.memberExpiresAt).isBefore(
      moment(constants.MEMBERSHIP_EXPIRY_DAY),
      'year'
    );

  useEffect(() => {
    if (disableMiroReg) {
      if (!(code && isLoading)) navigate('/miro-register');
    }
    if (isMiro && session?.user?.renewPrice < 0) navigate('/');
    const originalOverflow = document.body.style.overflow;

    // Set the body overflow to hidden
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore the original overflow value
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  useEffect(() => {
    if (code && data) {
      if (!data.success) {
        removeLateCode();
        setLateCode(null);
        if (isMiro) {
          navigate('/');
        } else {
          navigate('/miro-register');
        }
      } else {
        if (isLatestMiro) {
          removeLateCode();
          setLateCode(null);
          navigate('/');
        } else {
          setLateCode(code);
        }
      }
    }
  }, [data]);

  console.log('code', code, data);

  if (code && isLoading) {
    return <Loading />;
  }

  if (isError) {
    if (isMiro) {
      navigate('/');
    } else {
      navigate('/miro-register');
    }
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <iframe
        src={`${constants.JOIN_2025_URL}/${lang}?member=${memberType}`}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
}
