import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CommonButton from '../../../../components/common/buttons/CommonButton';
import { HomeMediaImage } from '../../../../components/common/images/ShadowImage';
import HomeTitleGroup from '../../../../components/common/typographies/2025HomeTitleGroup';
import { breakpoint } from '../../../../utils/responsive';
import CurrentItemDescription from '../CurrentItemDescription';
import { CarouselWrapper, SingleLineMediaWrapper } from './styled';

const MediaContainer = ({ mediaSeries, hasShopItems }) => {
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const firstImage = mediaSeries.slice(0, 1).map((media, index) => {
    return (
      <div
        style={{
          maxWidth: 575,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        key={media._id}
      >
        <HomeMediaImage
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
          src={media.thumbnail + '-thumbnail'}
          onClick={() => navigate(`/media-series/${media._id}`)}
          backdropStyles={{
            background: '#305681',
            opacity: 0.2,
            visibility: 'visible',
          }}
        />
        <CurrentItemDescription arrayOfItem={mediaSeries} idx={index} />
      </div>
    );
  });

  const firstThreeMediaImages = mediaSeries.slice(0, 3).map((media, index) => {
    return (
      <div
        style={{
          width: '33%',
          display: 'flex',
          flexDirection: 'column',
        }}
        key={media._id}
      >
        <HomeMediaImage
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          src={media.thumbnail + '-thumbnail'}
          onClick={() => navigate(`/media-series/${media._id}`)}
          backdropStyles={{
            background: '#305681',
            opacity: 0.2,
            visibility: 'visible',
          }}
        />
        <CurrentItemDescription arrayOfItem={mediaSeries} idx={index} />
      </div>
    );
  });

  const shouldDisplayOnlyOneImage = hasShopItems || !isLargerThan1024;
  return (
    <>
      {shouldDisplayOnlyOneImage ? (
        <CarouselWrapper background="#AFCDEE">
          <MediaContent images={firstImage} />
          <CommonButton
            type="outlined"
            style={{ margin: '40px auto' }}
            onClick={() => navigate('/media-series')}
          >
            {t('button.allMedia')}
          </CommonButton>
        </CarouselWrapper>
      ) : (
        <SingleLineMediaWrapper background="#AFCDEE">
          <MediaContent images={firstThreeMediaImages} />
          <CommonButton
            type="outlined"
            style={{ margin: '50px auto' }}
            onClick={() => navigate('/media-series')}
          >
            {t('button.allMedia')}
          </CommonButton>
        </SingleLineMediaWrapper>
      )}
    </>
  );
};

const MediaContent = ({ images }) => {
  return (
    <>
      <HomeTitleGroup
        zhTitle="多媒體區"
        enTitle="MEDIA"
        style={{ justifyContent: 'center' }}
      />
      <MediaImageContainer>{images}</MediaImageContainer>
    </>
  );
};

const MediaImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  @media ${breakpoint[1024]} {
    padding-left: 42px;
    padding-right: 42px;
  }

  @media ${breakpoint.largeDesktop} {
    padding-left: 50px;
    padding-right: 50px;
  }

  gap: 40px;
  flex: 1;
`;

export default MediaContainer;
