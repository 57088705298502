import { getOrderCounter, updateTmpUser, updateUser } from '../../apis';
import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import LanguageToggleButton from '../../components/common/buttons/LanguageToggleButton';
import CheckBox from '../../components/common/checkboxs/Checkbox';
import { useState, useRef, useContext } from 'react';
import CommonText from '../../components/common/typographies/CommonText';
import { Trans, useTranslation } from 'react-i18next';
import Space from '../../components/common/Space';
import { AppContext } from '../../AppContext';
import OrangeTick from '../../assets/orange_tick.svg';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-hk';
import constants from '../../constants';
import { Grid } from '@mui/material';
import checkAdult from '../../utils/checkAdult';
import { ResponsiveUI, breakpoint } from '../../utils/responsive';
import { formatAddress } from '../../utils/addressFormat';
import getSupportingMembersString from '../../utils/getSupportingMembersString';
import { useQuery } from 'react-query';

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

const InfoComponent = ({ t, verified = false, title, value }) => {
  return [
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <CommonText color="#515151" fontSize="14px" lineHeight="20px">
        {title}
      </CommonText>
      {verified && (
        <div style={{ display: 'flex' }}>
          <CommonText color="#FF5C00" fontSize="14px" lineHeight="20px">
            {t('signin:register.verified')}
          </CommonText>
          <img style={{ marginLeft: 9 }} src={OrangeTick} alt="tick" />
        </div>
      )}
    </div>,
    <ResponsiveUI
      mobile={
        <CommonText
          color="rgba(17, 17, 17, 1)"
          fontSize="16px"
          lineHeight="26px"
          style={{ marginBottom: 16 }}
        >
          {value}
        </CommonText>
      }
      desktop={
        <CommonText
          color="rgba(17, 17, 17, 1)"
          fontSize="18px"
          lineHeight="40px"
          style={{ marginBottom: 16 }}
        >
          {value}
        </CommonText>
      }
    />,
  ];
};

function getMemberValidityDescription(t, effectiveStart, effectiveEnd) {
  let now = moment();
  if (now.isAfter(effectiveStart)) {
    return t('signin:register.membership_validity_from_now', {
      end: effectiveEnd.locale('en').format(t('signin:renew.date_format')),
    });
  } else {
    return t('signin:register.membership_validity', {
      start: effectiveStart.locale('en').format(t('signin:renew.date_format')),
      end: effectiveEnd.locale('en').format(t('signin:renew.date_format')),
    });
  }
}

export default function Step5({ handlePageChange, setSessionExpired }) {
  const { t, i18n } = useTranslation(['profile', 'signin', 'common']);
  const { session, setSession, lateCode } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState(
    session?.user.hasOwnProperty('promotion') ? session.user.promotion : false
  );
  const [promotionOther, setPromotionOther] = useState(
    session?.user.hasOwnProperty('promotion_other')
      ? session.user.promotion_other
      : false
  );
  const [agree, setAgree] = useState(false);
  const [language, setLanguage] = useState(
    session?.user?.language || 'chinese'
  );
  const { data: dateData } = useQuery(
    ['getOrderCounter', session?.accessToken],
    () => getOrderCounter(session?.accessToken)
  );
  const deliveryDateOptions = dateData?.data;

  const handleNext = async (back = false) => {
    if (!loading) {
      setLoading(true);
      const body = {
        language,
        promotion,
        promotion_other: promotionOther,
      };
      console.log(body);
      let result = null;
      if (session?.user?.memberType == 'TMP')
        result = await updateTmpUser(session.accessToken, body);
      else result = await updateUser(session.accessToken, body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        setSession(newSession);
        if (back) handlePageChange('step4');
        else handlePageChange('step6');
      } else {
        if (result.error?.code == 'E0002') {
          setSessionExpired(true);
        } else if (back) handlePageChange('step4');
      }
      setLoading(false);
    }
  };

  let effectiveStart = moment(constants.MEMBERSHIP_START_DAY);
  let effectiveEnd = moment(constants.MEMBERSHIP_EXPIRY_DAY);
  return (
    <Container>
      <CommonText lineHeight="140%">
        {t('signin:register.step') + ' 5/6'}
      </CommonText>
      <Space size="30px" />
      <CommonText fontSize="18px" mFontSize="16px" lineHeight="140%">
        {t('signin:register.your_information')}
      </CommonText>
      <Space size="30px" />
      <CommonText
        style={{ width: '100%', textAlign: 'left', marginBottom: '8px' }}
        fontSize="16px"
        lineHeight="20px"
      >
        {t('signin:register.join_membership')}
      </CommonText>
      <MembershipContainer>
        <div style={{ textAlign: 'left' }}>
          <ResponsiveUI
            mobile={
              <CommonText style={{ padding: '4px 6px', height: 'auto' }}>
                {t('signin:register.membership')}
              </CommonText>
            }
            desktop={<CommonText>{t('signin:register.membership')}</CommonText>}
          />

          <ResponsiveUI
            mobile={
              <>
                <CommonText style={{ padding: '4px 6px', height: 'auto' }}>
                  {getMemberValidityDescription(
                    t,
                    effectiveStart,
                    effectiveEnd
                  )}
                </CommonText>
                {lateCode && (
                  <CommonText>
                    {t('signin:register.membership_latejoin_fee')}
                  </CommonText>
                )}
              </>
            }
            desktop={
              <>
                <CommonText>
                  {getMemberValidityDescription(
                    t,
                    effectiveStart,
                    effectiveEnd
                  )}
                </CommonText>
                {lateCode && (
                  <CommonText>
                    {t('signin:register.membership_latejoin_fee')}
                  </CommonText>
                )}
              </>
            }
          />
        </div>
        <PriceContainer>
          <CommonText color="#ffffff">
            {t('profile:membership.membership_price_year', {
              price: lateCode
                ? constants.LATE_MEMBER_PRICE_PER_YEAR
                : constants.MEMBER_PRICE_PER_YEAR,
            })}
          </CommonText>
        </PriceContainer>
      </MembershipContainer>
      <Space size="20px" />
      <InfoContainer>
        <InfoComponent
          t={t}
          verified
          title={t('signin:register.email')}
          value={session?.user?.email}
        />
        {session?.user?.contactEmail && (
          <InfoComponent
            t={t}
            verified
            title={t('signin:register.contact_email')}
            value={session?.user?.contactEmail}
          />
        )}
        {session?.user?.phone && (
          <InfoComponent
            t={t}
            title={t('signin:register.phone')}
            value={insert(session?.user?.phone, 4, ' ')}
          />
        )}
        <InfoComponent
          t={t}
          title={t('signin:register.english_firstname')}
          value={session?.user?.firstName}
        />
        <InfoComponent
          t={t}
          title={t('signin:register.english_lastname')}
          value={session?.user?.lastName}
        />
        {session?.user?.chineseName && (
          <InfoComponent
            t={t}
            title={t('signin:register.chinese_name')}
            value={session?.user?.chineseName}
          />
        )}
        <InfoComponent
          t={t}
          title={t('signin:register.birthday')}
          value={session?.user?.birthdayDate || ''}
        />
        {session?.user?.gender && (
          <InfoComponent
            t={t}
            title={t('signin:register.gender')}
            value={
              session?.user?.gender
                ? t(`common:gender.${session?.user?.gender}`)
                : ''
            }
          />
        )}
        <InfoComponent
          t={t}
          title={t('signin:register.supporting_members')}
          value={getSupportingMembersString(
            session?.user?.supportingMembers,
            i18n.language
          )}
        />
      </InfoContainer>
      <Space size="20px" />
      {!checkAdult(new Date(session?.user?.birthdayDate)) && (
        <div style={{ width: '100%' }}>
          <CommonText
            style={{ width: '100%', textAlign: 'left' }}
            fontSize="16px"
            lineHeight="20px"
          >
            {t('signin:parent.info')}
          </CommonText>
          <Space size="5px" />
          <InfoContainer>
            <InfoComponent
              t={t}
              title={t('signin:register.english_firstname')}
              value={session?.user?.parentFirstName}
            />
            <InfoComponent
              t={t}
              title={t('signin:register.english_lastname')}
              value={session?.user?.parentLastName}
            />
            <InfoComponent
              t={t}
              verified
              title={t('signin:register.parent_email')}
              value={session?.user?.parentEmail}
            />
          </InfoContainer>
          <Space size="20px" />
        </div>
      )}
      {session?.user?.addresses?.[0]?.date ? (
        <>
          <CommonText fontSize="18px" mFontSize="16px" lineHeight="40px">
            {t('signin:register.shipping_address')}
          </CommonText>
          <Space size="20px" />
          <AddressContainer>
            <CommonText color="#515151" fontSize="14px">
              {t('common:address.type')}
            </CommonText>
            <CommonText>
              {t('common:address.' + session?.user?.addresses[0]?.type)}
            </CommonText>
            <Space size="20px" />
            {!lateCode ? (
              <>
                <CommonText color="#515151" fontSize="14px">
                  {t('common:address.delivery_date')}
                </CommonText>
                <CommonText>
                  {
                    deliveryDateOptions?.find(
                      (x) => x._id == session?.user?.addresses?.[0]?.date
                    )[
                      i18n.language == 'zh'
                        ? 'deliveryDateZh'
                        : 'deliveryDateEn'
                    ]
                  }
                </CommonText>
                <Space size="20px" />
              </>
            ) : (
              <></>
            )}
            <CommonText color="#515151" fontSize="14px">
              {t('common:address.Express_address')}
            </CommonText>
            <CommonText>
              {formatAddress(session?.user?.addresses[0], i18n.language)}
            </CommonText>
            <Space size="20px" />
            <CommonText color="#515151" fontSize="14px">
              {t('common:address.name')}
            </CommonText>
            <CommonText>{session?.user?.addresses[0]?.name}</CommonText>
            <Space size="20px" />
            <CommonText color="#515151" fontSize="14px">
              {t('common:address.phone')}
            </CommonText>
            <CommonText>{session?.user?.addresses[0]?.phone}</CommonText>
          </AddressContainer>
        </>
      ) : (
        <>
          <div style={{ width: '100%' }}>
            <CommonText fontSize="18px" mFontSize="16px" lineHeight="40px">
              {t('signin:register.optout_gift_pack_title')}
            </CommonText>
          </div>
          <AddressContainer>
            <CommonText>
              {t('signin:register.optout_gift_pack_text')}
            </CommonText>
          </AddressContainer>
        </>
      )}

      <Space size="22px" />
      <Grid container justifyContent="flex-start">
        <CheckBox
          onClick={() => setAgree(!agree)}
          isChecked={agree}
          mFontSize="14px"
        >
          <Trans
            t={t}
            i18nKey="signin:register.miro_agreement"
            components={[
              <Link
                style={{
                  color: '#FF5C00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={0}
                target="_blank"
                to="/tnc?id=web"
              />,
              <Link
                style={{
                  color: '#FF5C00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={1}
                target="_blank"
                to="/tnc?id=membership"
              />,
              <Link
                style={{
                  color: '#FF5C00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={2}
                target="_blank"
                to="/privacy-terms"
              />,
              <a
                style={{
                  color: '#FF5C00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                key={3}
                target="_blank"
                href={`http://www.pccw.com/privacy-statement/index.page?locale=${i18n.language}`}
              />,
            ]}
          />
        </CheckBox>
      </Grid>
      <Space size="22px" />
      <CheckBox
        onClick={() => setPromotion(!promotion)}
        isChecked={promotion}
        mFontSize="14px"
      >
        {t('signin:renew.tnc2')}
      </CheckBox>
      <Space size="22px" />
      <CheckBox
        onClick={() => setPromotionOther(!promotionOther)}
        isChecked={promotionOther}
        mFontSize="14px"
      >
        {t('signin:renew.tnc3')}
      </CheckBox>
      <Space size="20px" />
      <CommonText
        style={{ width: '100%', textAlign: 'left' }}
        fontSize="16px"
        mFontSize="14px"
        lineHeight="20px"
      >
        {t('profile:info.receive_promote_language')}
      </CommonText>
      <Space size="9px" />
      <LanguageToggleButton
        value={language}
        onChange={(event) => setLanguage(event.target.value)}
      />
      <Space size="64px" />
      <ButtonContainer>
        <CommonButton
          style={{ width: '50%', maxWidth: 440 }}
          onClick={() => handleNext(true)}
        >
          {t('signin:register.previous')}
        </CommonButton>
        <CommonButton
          disabled={!agree}
          style={{ width: '50%', maxWidth: 440, marginLeft: 30 }}
          type={'primary'}
          onClick={() => handleNext(false)}
        >
          {t('signin:register.next')}
        </CommonButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
  text-align: left;
`;
const MembershipContainer = styled.div`
  display: flex;
  border: 2px solid #111111;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 20px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;
const PriceContainer = styled.div`
  display: flex;
  background: #111111;
  border-radius: 5px;
  width: 165px;
  height: 30px;
  justify-content: center;
  align-items: center;

  @media ${breakpoint.mobile} {
    padding: 4px 6px;
    height: auto;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 20px;
  width: 100%;
`;
const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 20px;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;
